import { Controller } from "@hotwired/stimulus";

const UP = "UP";
const DOWN = "DOWN";
const STATIC = "STATIC";

export default class extends Controller {
  static targets = ["header"];

  connect() {
    console.log("connecting...")
    this.handleScrollInterval = this.handleScrollInterval.bind(this);
    this.interval = setInterval(this.handleScrollInterval, 100);

    addEventListener("hashchange", (event) => {
      this.handleHashChange()
    })
    if (window.location.hash) {
      this.showMenu()
    }
  }

  showMenu() {
    setTimeout(() => {
      window.scrollBy(0, -(document.getElementsByClassName('header-spacer')[0].clientHeight))
    }, 1200)
  }

  handleHashChange() {
    // for mobile menu uncheck to hide the menu
    document.getElementById("nav-menu-toggle").checked = false
    this.showMenu()
  }
  handleScrollInterval() {
    // Safari can overscroll negatively at the top of the scroll; the journey back to zero makes
    // it seem like the user is scrolling downwards, but they are not, so make 0 the minimum value
    // Similarly it can extend beyond the real scrollable value at the bottom of the page. Again
    // the spring back to the correct position is not movement that is coming from the user, so cap
    // the scroll value at the other end to the maximum permissable value
    const maxScrollY = document.body.scrollHeight - window.innerHeight;
    const scrollY = Math.min(Math.max(window.scrollY, 0), maxScrollY);

    if (this.scrollY > scrollY) {
      if (this.direction != UP) {
        this.direction = UP;
        this.headerTarget.classList.remove("hide");
      }
    } else if (this.scrollY === scrollY) {
      if (this.direction != STATIC) {
        this.direction = STATIC;
      }
    } else {
      if (this.direction != DOWN && scrollY > 200) {
        this.direction = DOWN;
        this.headerTarget.classList.add("hide");
      }
    }
    this.scrollY = scrollY;
  }
}
